<template>
  <div id="btn-to-film">
    <a href="http://redhot-film.com/" target="_blank">
      <button>
        <span>REDHOT SHOWREEL &gt;</span>
      </button>
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@media all and (min-width: 768px) {
  #btn-to-film {
    position: fixed;
    z-index: 999999;
    bottom: 15vh;
    margin: 0 0 0 auto;
    width: 100vw;
    text-align: right;
  }
  button {
    margin: 0 4vw 0 0;
    padding: 0.5rem 2.5rem;
    background: #fff;
    border: 0;
    box-shadow: 4px 10px 20px rgba(0, 0, 0, 0.1);
  }
  button:hover {
    cursor: pointer;
  }
  button:hover {
    cursor: pointer;
    background: #ea3022;
    box-shadow: 4px 10px 20px rgba(0, 0, 0, 0.4);
    transition: 0.2s;
  }
  button:hover span {
    color: #fff;
    transition: 0.3s;
  }
  button span {
    color: #ea3022;
    font-size: 1.5rem;
    font-weight: 900;
  }
}
@media all and (max-width: 767px) {
  #btn-to-film {
    position: fixed;
    z-index: 999999;
    bottom: 12vh;
    margin: 0 0 0 auto;
    width: 100vw;
    text-align: right;
  }
  button {
    margin: 0 5vw 0 0;
    box-shadow: 4px 10px 20px rgba(0, 0, 0, 0.1);
    padding: 0.5em 3em;
    background: #fff;
    border: 0;
  }
  button:hover {
    cursor: pointer;
  }
  button:hover {
    cursor: pointer;
    background: #ea3022;
    box-shadow: 4px 10px 20px rgba(0, 0, 0, 0.4);
    transition: 0.2s;
  }
  button:hover > span {
    color: #fff;
    transition: 0.3s;
  }
  button span {
    color: #ea3022;
    font-size: 1.3rem;
    font-weight: 900;
  }
}
</style>
