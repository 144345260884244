<template>
  <div class="login-dim">
    <div class="login-wrap">
      <div class="login-container">
        <div class="login-title">
          <img src="../assets/logo.svg" alt="logo">
          <h1>관리자만 이용할 수 있습니다</h1>
        </div> <!-- login-title -->
        <div class="login-form">
          <input 
          type="email"
          placeholder="abc@def.com"
          v-model="email"
          /> <br>
          <input 
          type="password"
          placeholder="password"
          v-model="pwd"
          /> <br>
          <button
          @click="login">
            로그인
          </button>
          <!-- <button
          @click="signUp"
          >
          회원가입
          </button> -->
        </div> <!-- login-form -->
      </div> <!-- login-container -->
    </div> <!-- login-container -->
  </div> <!-- login-dim -->
</template>

<script>

export default {
  metaInfo(){
        return {
            titleTemplate: 'REDHOT LOGIN'
        }
    },
  data() {
    return {
      email: '',
      pwd: ''
    }
  },
  methods: {
    login() {
      const self = this
      this.$store.dispatch('login', {
        email: this.email,
        pwd: this.pwd
      }).then(user => {
        if (user.uid) {
          console.log(user.email, '으로 로그인하였습니다')
          self.$router.push("/admin")
        }
      })
    },

  },
}
</script>

<style>

/* 로그인 페이지  */
.login-dim {
  width: 100vw;
  height: 100vh;
  display: table;
  background: rgba(0, 0, 0, 0.4);
}
.lgoin-dim .login-wrap {
  position: relative;
  display: table-cell;
}
.login-dim .login-container {
  width: 400px;
  height: 500px;
  background: white;
  margin: 20vh auto 0;
  vertical-align: middle;
  border-radius: 20px 0 20px 20px;
  box-shadow: 2px 10px 30px 10px rgba(0, 0, 0, 0.4);
  color: #222;
  z-index: 2001;
}
/* 로그인 타이틀 */
.login-dim .login-container .login-title img {
  width: 50px;
  height: 50px;
  margin: 30px 0 20px;
}
.login-dim .login-container .login-title {
  text-align: center;
  margin: 0 auto 20px 0;
  padding-top: 40px;
  font-size:15pt;
}
/* 로그인 폼 */
.login-dim .login-container .login-form {
  width: 80%;
  margin: 35px auto 0;
}
.login-dim .login-container .login-form input {
  width: 100%;
  height: 50px;
  margin: 10px 0;
  font-size: 18pt;
  border: 0;
  border-bottom: 1px solid #222;
  box-sizing: border-box;
}
.login-dim .login-container .login-form input:focus {
  transition: 0.3s;
  border-bottom: 4px solid #EC2026;
}
.login-dim .login-container .login-form button {
  width: 100%; height: 40px;
  appearance: none;
  border: 1px solid #222;
  margin: 20px auto 0;
  font-size: 12pt;
  border-radius: 10px 0 10px 10px;
}
.login-dim .login-container .login-form button:hover, .login-dim .login-container .login-form button:focus {
  transition: 0.7s;
  color: white;
  background:  #EC2026;
}
</style>
