<template>
  <div class="work-detail">
    <div class="gnb-bg"></div>
    <div class="work-detail-container">
      <div class="gnb-bg"></div>
      <div class="detail-title">
        <h1>{{ currentWork.title }}</h1>
        <h2>{{ currentWork.client }}</h2>
      </div>
      <div class="detail-content">
        <div class="embeded-container">
          <vimeo-player
            class="vp-full"
            :video-id="currentWork.videoURL"
            player-width="1540"
            player-height="900"
          >
          </vimeo-player>
          <vimeo-player
            class="vp-mobile"
            :video-id="currentWork.videoURL"
            player-width="375"
            player-height="210.9375"
          >
          </vimeo-player>
        </div>
      </div>
    </div>
    <div class="work-detail-aside">
      <div class="aside-container">
        <ul>
          <li v-if="currentWork.agency !== ''">
            AGENCY : {{ currentWork.agency }}
          </li>
          <li v-if="currentWork.director !== ''">
            DIRECTOR: {{ currentWork.director }}
          </li>
          <li v-if="currentWork.dop !== ''">D.O.P : {{ currentWork.dop }}</li>
          <li v-if="currentWork.gf !== ''">G.F : {{ currentWork.gf }}</li>
          <li v-if="currentWork.art !== ''">ART : {{ currentWork.art }}</li>
          <li v-if="currentWork.location !== ''">
            LOCATION : {{ currentWork.location }}
          </li>
          <li v-if="currentWork.modelAgency !== ''">
            MODEL AGENCY : {{ currentWork.modelAgency }}
          </li>
          <li v-if="currentWork.style !== ''">
            STYLE : {{ currentWork.style }}
          </li>
          <li v-if="currentWork.handm !== ''">
            H&amp;M : {{ currentWork.handm }}
          </li>
          <li v-if="currentWork.edit !== ''">EDIT : {{ currentWork.edit }}</li>
          <li v-if="currentWork.vfx !== ''">VFX : {{ currentWork.vfx }}</li>
          <li v-if="currentWork.colorgranding !== ''">
            COLORGRADING : {{ currentWork.colorgranding }}
          </li>
          <li v-if="currentWork.sound !== ''">
            SOUND : {{ currentWork.sound }}
          </li>
          <li v-if="currentWork.audiopd !== ''">
            AUDIO PD : {{ currentWork.audiopd }}
          </li>
        </ul>
      </div>
    </div>
    <div class="back-btn-wrapper">
      <div class="back-btn-container" @click="$router.replace('/film')">
        <div class="back-btn">
          &lt; BACK
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { vueVimeoPlayer } from 'vue-vimeo-player'

export default {
  metaInfo() {
    return {
      titleTemplate: "FILM | " + this.currentWork.title,
    };
  },
  components: {
    // vueVimeoPlayer,
  },
  data() {
    return {
      index: this.$route.path.split("/")[2],
    };
  },
  computed: {
    currentWork() {
      return (
        this.$store.getters.allFilm.find((r) => r.uid == this.index) || false
      );
    },
  },
};
</script>

<style scoped>
@media all and (min-width: 768px) {
  .work-detail {
    width: 94vw;
    height: inherit;
    margin: 16vh auto;
  }
  .work-detail > div {
    vertical-align: top;
    overflow: hidden;
  }
  .work-detail-container {
    min-width: 1080px;
  }
  /* gnb-bg */
  .gnb-bg {
    width: 100vw;
    height: 88px;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }
  /* 작품 내용물 */
  .embeded-container {
    width: 94vw;
    height: inherit;
  }
  #vimeo-player-5 {
    position: relative;
    padding-bottom: 56.25%; /* 16/9 ratio */
    padding-top: 30px; /* IE6 workaround*/
    height: 0;
    overflow: hidden;
    margin: 0;
  }
  #vimeo-player-5 iframe,
  #vimeo-player-5 object,
  #vimeo-player-5 embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .vp-full {
    display: block;
  }
  .vp-mobile {
    display: none;
  }

  /* 작품 내용정보 */
  /* 작품 제목 */
  .work-detail-container .detail-title {
    margin: 0 0 36px;
  }
  .work-detail-container .detail-title h1 {
    width: 60vw;
    font-size: 2rem;
    color: #222;
  }
  .work-detail-container .detail-title h2 {
    font-size: 1.6rem;
    margin: 8px 0;
    color: #444;
  }
  .work-detail-aside {
    width: 60vw;
  }
  .work-detail-aside .aside-container {
    width: 60vw;
    margin: 0 auto;
  }
  .aside-container ul {
    margin: 8px 0 0 0;
  }
  .aside-container ul li {
    color: #444;
    display: inline-block;
    font-size: 0.9rem;
    line-height: 16pt;
    margin: 0 32px 0 0;
    color: #999;
  }

  /* 뒤로가기 버튼 */
  .back-btn-wrapper {
    width: 80px;
    height: 80px;
    background: #ec2026;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    margin: 56px 0;
  }
  .back-btn-container {
    width: 80px;
    height: 80px;
    display: table;
  }
  .back-btn {
    text-align: center;
    width: 80px;
    height: 80px;
    font-weight: 900;
    display: table-cell;
    vertical-align: middle;
  }
}

@media all and (max-width: 767px) {
  .work-detail {
    width: 60vw;
    height: inherit;
    margin: 16vh auto;
    text-align: center;
  }
  .work-detail > div {
    vertical-align: top;
  }
  /* 작품 제목 */
  .work-detail-container h1 {
    font-size: 2rem;
    margin-bottom: 32px;
    color: #222;
  }
  .work-detail-container h2 {
    font-size: 1.6rem;
    margin-bottom: 56px;
    color: #444;
  }
  /* 작품 내용물 */
  .embeded-container {
    width: 76vw;
    height: inherit;
    margin: 0 0 0 -20vw;
  }
  #vimeo-player-5 {
    position: relative;
    padding-bottom: 56.25%; /* 16/9 ratio */
    padding-top: 30px; /* IE6 workaround*/
    height: 0;
    overflow: hidden;
    margin: 0;
  }
  #vimeo-player-5 iframe,
  #vimeo-player-5 object,
  #vimeo-player-5 embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .vp-full {
    display: none;
  }
  .vp-mobile {
    display: block;
  }

  /* 작품 내용정보 */
  /* 작품 제목 */
  .work-detail-container .detail-title {
    margin: 24px 0 0 0;
  }
  .work-detail-container .detail-title h1 {
    width: 60vw;
    font-size: 1.4rem;
    color: #222;
  }
  .work-detail-container .detail-title h2 {
    font-size: 1.2rem;
    margin: 8px 0 16px;
    color: #444;
  }
  .work-detail-aside {
    width: 60vw;
  }
  .work-detail-aside .aside-container {
    width: 60vw;
    margin: 0 auto;
  }
  .aside-container ul {
    margin: 8px 0 0 0;
    font-weight: normal !important;
  }
  .aside-container ul li {
    display: inline-block;
    font-size: 0.9rem;
    line-height: 16pt;
    margin: 0 auto;
    color: #999;
  }

  /* 뒤로가기 버튼 */
  .back-btn-wrapper {
    width: 80px;
    height: 80px;
    background: #ec2026;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    margin: 56px auto;
  }
  .back-btn-container {
    width: 80px;
    height: 80px;
    display: table;
  }
  .back-btn {
    text-align: center;
    width: 80px;
    height: 80px;
    font-weight: 900;
    display: table-cell;
    vertical-align: middle;
  }
}
</style>
