<template>
  <div class="contact">
    <!-- <div class="gnb-bg"></div> -->
    <div
      id="map"
      :style="{ backgroundImage: `url(${require('../assets/map.png')})` }"
    ></div>
    <div class="contact-container">
      <div class="contact-form">
        <div class="form-container">
          <input
            class="form-first-name"
            type="text"
            placeholder="FIRST NAME"
            v-model="contactContent.first_name"
          />
          <input
            class="form-last-name"
            type="text"
            placeholder="LAST NAME"
            v-model="contactContent.last_name"
          />
          <input
            class="form-email"
            type="text"
            placeholder="EMAIL ADDRESS"
            v-model="contactContent.email"
          />
          <textarea
            class="form-message"
            placeholder="MESSAGE"
            v-model="contactContent.context"
          ></textarea>
          <p>
            <input
              type="checkbox"
              id="form-checkbox"
              class="form-checkbox"
              vlaue="ready"
              v-model="submitReady"
            />
            개인정보 수집 및 이용 동의 /
            <u @click="condition = !condition">약관 보기</u>
            <textarea
              class="condition"
              :class="{ conditionActive: condition }"
              disabled
            >
    개인정보 수집 및 이용 동의
    ■ 개인정보 수집목적 및 이용목적
    문의 및 메시지 접수
    ■ 수집하는 개인정보 항목
    성명, 이메일, 주소 등
    ■ 개인정보의 보유기간 및 이용기간
    관계 법령의 규정에 따라 귀하의 개인정보를 보존할 의무가 있는 경우가 아닌 한, 회사는 위의 수집 및 이용목적을 달성할 때까지 귀하의 개인정보를 보유 및 이용합니다.
    * 귀하는 위와 같은 일반 개인정보의 수집 및 이용을 거부할 수 있습니다. 다만, 일반 개인정보의 필수적 수집 및 이용에 동의하지 않을 경우 문의 및 메시지 서비스 이용이 불가능합니다.
                    </textarea
            >
          </p>
        </div>
        <button
          class="btn-disabled"
          for="form-checkbox"
          v-if="submitReady == ``"
          @click="alertDisabled"
        >
          SUBMIT
        </button>
        <button
          class="btn-submit"
          for="form-checkbox"
          v-if="submitReady == true"
          @click="submit"
        >
          SUBMIT
        </button>
      </div>
      <div class="contact-info">
        <div class="info-container">
          <ul class="info-list">
            <li><strong>TEL</strong> +82 (02) 518-4642</li>
            <li><strong>FAX</strong> +82 (02) 6925-1024</li>
            <li><strong>Email</strong>redhot@redhotcontents.com</li>
            <li>
              <strong>ADDRESS</strong>3th, Dongin BLDG, 18, Eonju-ro 130-gil,<br /><strong></strong>
              Gangnam-gu, Seoul, South Korea
            </li>
          </ul>
        </div>
      </div>
    </div>
    <film-btn></film-btn>
  </div>
</template>

<script>
import FilmBtn from "../components/FilmBtn";
import emailjs from "emailjs-com";

export default {
  components: {
    FilmBtn,
  },
  metaInfo() {
    return {
      titleTemplate: "REDHOT | CONTACT",
    };
  },
  data() {
    return {
      condition: false,
      submitReady: ``,
      contactContent: {
        first_name: ``,
        last_name: ``,
        email: ``,
        context: ``,
      },
    };
  },
  methods: {
    submit() {
      const serviceID = `redhotcontents`;
      const templateID = `template_df8YZZ3S`;
      emailjs.send(serviceID, templateID, this.contactContent).then(function() {
        alert("감사합니다, 최대한 빨리 연락드리겠습니다.");
      });
      this.contactContent.first_name = ``;
      this.contactContent.last_name = ``;
      this.contactContent.email = ``;
      this.contactContent.context = ``;
    },
    alertDisabled() {
      alert("개인정보 수집이용 동의에 체크해주세요.");
    },
  },
};
</script>

<style scoped>
@media all and (min-width: 768px) {
  .contact {
    width: 100vw;
  }
  /* gnb-bg */
  .gnb-bg {
    width: 100vw;
    height: 96px;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }

  /* 맵 */
  #map {
    width: 100vw;
    height: 70vh;
    position: relative;
    top: 70px;
    z-index: -1;
    background-size: cover;
    background-repeat: no-repeat;
  }

  /* 연락 정보 */
  .contact-container {
    width: 100vw;
    margin: 70px 0 0;
    display: table;
  }
  .contact-container > div {
    height: 60vh;
    display: table-cell;
    vertical-align: middle;
  }

  /* form */
  .contact-container .contact-form {
    width: 50vw;
    padding: 5vh 8vw;
  }
  .contact-container .contact-form .form-container {
    width: 42vw;
    height: 60vh;
  }
  .contact-container .contact-form .form-container input[type="text"] {
    padding: 1vh 1vw;
    display: inline-block;
    color: #444;
    border: 1px solid #999;
    appearance: none;
  }
  .contact-container .contact-form .form-container .form-first-name {
    width: 40%;
    margin: 0 5% 0 0;
  }
  .contact-container .contact-form .form-container .form-last-name {
    width: 39%;
  }
  .contact-container .contact-form .form-container .form-email {
    width: 89%;
    margin: 1vh 0 0;
  }
  .contact-container .contact-form .form-container .form-message {
    width: 89%;
    height: 40vh;
    margin: 1vh 0 0;
    padding: 1vh 1vw;
    border: 0;
    appearance: none;
    resize: none;
    border: 1px solid #999;
  }
  .contact-container .contact-form .form-container p {
    margin: 1vh 0;
    font-weight: 500 !important;
    color: #999;
  }
  .contact-container .contact-form .form-container u {
    cursor: pointer;
  }
  .condition {
    width: 89%;
    height: 5vh;
    margin: 1vh 0;
    padding: 1vh 1vw;
    line-height: 1.3rem;
    background: #ededed;
    color: #999;
    display: none;
  }
  .conditionActive {
    display: block;
  }
  .btn-disabled {
    width: 140px;
    height: 40px;
    border: 0;
    margin: 4vh 0;
    appearance: none;
    background: #999;
    color: #fff;
  }
  .btn-submit {
    width: 140px;
    height: 40px;
    border: 0;
    margin: 4vh 0;
    appearance: none;
    background: #ea3022;
    color: #fff;
  }

  /* contact-info */
  .contact-container .contact-info {
    width: 40vw;
    padding: 5vh 2vw;
  }
  .contact-container .info-container {
    width: 40vw;
    height: 71vh;
  }
  .contact-container .info-container .info-list li {
    font-weight: 400 !important;
    line-height: 1.5rem;
    margin: 0 0 2vh;
    color: #666;
    font-size: 0.9rem;
  }
  .contact-container .info-container .info-list strong {
    display: inline-block;
    width: 5vw;
    min-width: 96px;
    font-weight: 700;
    color: #222;
    font-size: 1rem;
  }
}

@media all and (max-width: 767px) {
  .contact {
    width: 100vw;
    min-height: 812px;
  }
  /* gnb-bg */
  .gnb-bg {
    width: 100vw;
    height: 96px;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }

  /* 맵 */
  #map {
    width: 100vw;
    height: 487.2px;
    position: relative;
    top: 60px;
    z-index: -1;
    background-size: cover;
    background-position-x: -300px;
    background-repeat: no-repeat;
  }

  /* 연락 정보 */
  .contact-container {
    width: 100vw;
    margin: 70px 0 0;
  }

  /* form */
  .contact-container .contact-form {
    width: 92vw;
    margin: 108px auto;
  }
  .contact-container .contact-form .form-container {
    width: 92vw;
    height: 487.2px;
  }
  .contact-container .contact-form .form-container input[type="text"] {
    padding: 8px 12px;
    color: #444;
    border: 1px solid #999;
    appearance: none;
  }
  .contact-container .contact-form .form-container .form-first-name {
    width: 40%;
    margin: 0 5% 0 0;
  }
  .contact-container .contact-form .form-container .form-last-name {
    width: 39%;
  }
  .contact-container .contact-form .form-container .form-email {
    width: 92%;
    margin: 8px 0 0;
  }
  .contact-container .contact-form .form-container .form-message {
    width: 92%;
    height: 360px;
    margin: 8px 0 0;
    padding: 8px 12px;
    border: 0;
    appearance: none;
    resize: none;
    border: 1px solid #999;
  }
  .contact-container .contact-form .form-container p {
    margin: 1vh 0;
    font-weight: 500 !important;
    color: #999;
  }
  .contact-container .contact-form .form-container u {
    cursor: pointer;
  }
  .condition {
    width: 80vw;
    height: 56px;
    margin: 72px 0 0;
    padding: 8px 12px;
    line-height: 1.3rem;
    background: #ededed;
    color: #999;
    display: none;
  }
  .conditionActive {
    display: block;
  }
  .btn-disabled {
    width: 140px;
    height: 40px;
    border: 0;
    margin: 4vh 0;
    appearance: none;
    background: #999;
    color: #fff;
  }
  .btn-submit {
    width: 140px;
    height: 40px;
    border: 0;
    margin: 21px 0;
    appearance: none;
    background: #ea3022;
    color: #fff;
  }

  /* contact-info */
  .contact-container .contact-info {
    width: 80vw;
    height: 320px;
    margin: 112px 0 0 8vw;
  }
  .contact-container .info-container {
    width: 80vw;
  }
  .contact-container .info-container .info-list li {
    font-weight: 400 !important;
    line-height: 1.5rem;
    margin: 0 0 16px;
    color: #666;
    font-size: 0.7rem;
  }
  .contact-container .info-container .info-list strong {
    display: inline-block;
    width: 88px;
    font-weight: 700;
    color: #222;
    font-size: 1rem;
  }
}
</style>
