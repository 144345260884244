<template>
  <div class="work-detail">
    <!-- <div class="gnb-bg"></div> -->
    <div class="work-detail-container">
      <div class="detail-title">
        <h1>{{ currentWork.title }}</h1>
        <h2>{{ currentWork.client }}</h2>
      </div>
      <!-- <div class="detail-content" v-if="currentWork.videoURL !== ''">
            <div class="embeded-container">
                <vimeo-player
                class="vp-full"
                :video-id='currentWork.videoURL'
                player-width='1280'
                player-height='680'
                >
                </vimeo-player>
                <vimeo-player
                class="vp-mobile"
                :video-id='currentWork.videoURL'
                player-width='345'
                player-height='194.0625'
                >
                </vimeo-player>
            </div>
        </div> -->
    </div>
    <!-- <div class="desc-context">
        <table>
            <tr>
                <th v-if="currentWork.category !== ' ' && currentWork.category !== ''">CATEGORIES - {{currentWork.category}}</th>
                <th v-if="currentWork.videoURL !== ' ' && currentWork.videoURL !== ''">Vimeo - {{currentWork.videoURL}}</th>
            </tr>
            <tr>
                <td v-if="currentWork.descText !== ' ' && currentWork.descText !== ''">{{currentWork.descText}}</td>
                <td v-if="currentWork.descText2 !== ' ' && currentWork.descText2 !== ''">{{currentWork.descText2}}</td>
            </tr>
        </table>
    </div> -->
    <!-- <div class="desc-title">
        <h1 v-if="currentWork.descTitle !== ''">{{currentWork.descTitle}}</h1>
    </div> -->
    <div class="desc-container" v-if="currentWork.desc !== ''">
      <div class="desc" v-html="currentWork.desc"></div>
    </div>
    <div class="work-detail-aside">
      <div class="aside-container">
        <h4>CREDITS</h4>
        <ul>
          <li v-if="currentWork.agency !== ''">
            AGENCY : {{ currentWork.agency }}
          </li>
          <li v-if="currentWork.director !== ''">
            DIRECTOR: {{ currentWork.director }}
          </li>
          <li v-if="currentWork.dop !== ''">D.O.P : {{ currentWork.dop }}</li>
          <li v-if="currentWork.gf !== ''">G.F : {{ currentWork.gf }}</li>
          <li v-if="currentWork.art !== ''">ART : {{ currentWork.art }}</li>
          <li v-if="currentWork.location !== ''">
            LOCATION : {{ currentWork.location }}
          </li>
          <li v-if="currentWork.modelAgency !== ''">
            MODEL AGENCY : {{ currentWork.modelAgency }}
          </li>
          <li v-if="currentWork.style !== ''">
            STYLE : {{ currentWork.style }}
          </li>
          <li v-if="currentWork.handm !== ''">
            H&amp;M : {{ currentWork.handm }}
          </li>
          <li v-if="currentWork.edit !== ''">EDIT : {{ currentWork.edit }}</li>
          <li v-if="currentWork.vfx !== ''">VFX : {{ currentWork.vfx }}</li>
          <li v-if="currentWork.colorgranding !== ''">
            COLORGRADING : {{ currentWork.colorgranding }}
          </li>
          <li v-if="currentWork.sound !== ''">
            SOUND : {{ currentWork.sound }}
          </li>
          <li v-if="currentWork.audiopd !== ''">
            AUDIO PD : {{ currentWork.audiopd }}
          </li>
        </ul>
      </div>
    </div>
    <div class="back-btn-wrapper">
      <div class="back-btn-container" @click="$router.push('/contents')">
        <div class="back-btn">
          &lt; BACK
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { vueVimeoPlayer } from 'vue-vimeo-player'

export default {
  metaInfo() {
    return {
      titleTemplate: "WORKS | " + this.currentWork.title,
    };
  },
  components: {
    // vueVimeoPlayer,
  },
  data() {
    return {
      index: this.$route.path.split("/")[2],
    };
  },
  computed: {
    currentWork() {
      return (
        this.$store.getters.allContents.find((r) => r.uid == this.index) ||
        false
      );
    },
  },
  mounted() {
    this.$store.commit("loadAllContents");
  },
};
</script>

<style scoped>
@media all and (min-width: 768px) {
  .work-detail {
    width: 94vw;
    height: inherit;
    margin: 116px auto 16vh;
  }

  .work-detail > div {
    vertical-align: top;
    overflow: hidden;
  }
  .work-detail-container {
    min-width: 720px;
  }
  /* 작품 내용물 */
  .embeded-container {
    text-align: center;
    width: 94vw;
    height: inherit;
    margin: 0 auto;
  }
  #vimeo-player-5 {
    position: relative;
    height: 0;
    overflow: hidden;
    margin: 0;
    padding-bottom: 0 !important;
  }
  #vimeo-player-5 iframe,
  #vimeo-player-5 object,
  #vimeo-player-5 embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .vp-full {
    display: block;
  }
  .vp-mobile {
    display: none;
  }

  /* 작품 내용정보 */
  /* 작품 제목 */
  .work-detail-container .detail-title {
    margin: 0 0 16px 14vw;
  }
  .work-detail-container .detail-title h1 {
    width: 60vw;
    font-size: 3rem;
    color: #222;
  }
  .work-detail-container .detail-title h2 {
    font-size: 1.6rem;
    margin: 8px 0;
    color: #444;
  }
  .work-detail-aside {
    width: 66vw;
    margin: 0 auto;
  }
  .work-detail-aside .aside-container {
    width: 68vw;
    margin: 0 auto;
  }
  .work-detail-aside .aside-container h4 {
    font-size: 1.4rem;
  }
  .work-detail-aside .aside-container ul {
    margin: 8px 0 0 0;
  }
  .aside-container ul li {
    display: inline-block;
    font-size: 0.9rem;
    line-height: 16pt;
    margin: 0 32px 0 0;
    color: #999;
  }
  /* 추가 지면이미지 */
  .desc-context {
    width: 60vw;
    margin: 4vh auto;
  }
  .desc-context table tr th {
    width: 35vw;
    font-size: 1.3rem;
    color: #666;
    text-align: left;
    font-weight: 500;
  }
  .desc-context table tr td {
    width: 60vw;
    padding: 32px 16px;
    color: #666;
    line-height: 1.6rem;
  }
  .desc-title {
    width: 69vw;
    margin: 72px 0 0 14vw;
    font-size: 2.5rem;
    color: #000;
  }
  .desc-container {
    width: 68vw;
    margin: 0 auto;
  }
  .desc {
    margin: 16px 0 0 0;
    width: 100%;
    word-break: keep-all;
  }
  .desc /deep/ img {
    width: 100%;
    margin: 1vh 0;
    display: block;
  }
  .desc /deep/ h1 {
    font-size: 2.4rem;
    line-height: 3rem;
  }
  .desc /deep/ h2 {
    font-size: 2rem;
    line-height: 2.8rem;
  }
  .desc /deep/ h3 {
    font-size: 1.7rem;
    line-height: 2.2rem;
  }
  .desc /deep/ h4 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .desc /deep/ h5 {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  .desc /deep/ strong {
    font-weight: 700;
  }
  .desc /deep/ em {
    font-style: oblique;
  }
  .desc /deep/ p {
    font-size: 0.9rem;
    line-height: 1.6rem;
  }
  .desc /deep/ iframe {
    margin: 32px auto;
    width: 100%;
    height: 67.25vh;
  }

  /* 뒤로가기 버튼 */
  .back-btn-wrapper {
    width: 80px;
    height: 80px;
    background: #e60121;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    margin: 56px 0 0 14vw;
  }
  .back-btn-container {
    width: 80px;
    height: 80px;
    display: table;
  }
  .back-btn {
    text-align: center;
    width: 80px;
    height: 80px;
    font-weight: 900;
    display: table-cell;
    vertical-align: middle;
  }
}

@media all and (max-width: 767px) {
  .work-detail {
    width: 92vw;
    height: inherit;
    margin: 120px auto 0;
  }
  .work-detail > div {
    vertical-align: top;
  }
  /* gnb-bg */
  .gnb-bg {
    width: 100vw;
    height: 72px;
    background: #fff;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
  }
  /* 작품 내용물 */
  .embeded-container {
    width: 100vw;
    height: inherit;
  }
  #vimeo-player-5 {
    position: relative;
    padding-bottom: 56.25%; /* 16/9 ratio */
    padding-top: 30px; /* IE6 workaround*/
    height: 0;
    overflow: hidden;
    margin: 0;
  }
  #vimeo-player-5 iframe,
  #vimeo-player-5 object,
  #vimeo-player-5 embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .vp-full {
    display: none;
  }
  .vp-mobile {
    display: block;
  }

  /* 작품 내용정보 */
  /* 작품 제목 */
  .desc-context {
    width: 92vw;
    margin: 32px auto;
  }
  .desc-context table tr th {
    display: block;
    width: 90vw;
    margin: 8px auto;
    font-size: 1.1rem;
    color: #666;
    text-align: left;
  }
  .desc-context table tr td {
    display: block;
    width: 90vw;
    margin: 8px auto;
    padding: 8px;
    color: #666;
    line-height: 1.4rem;
    text-align: left;
  }
  .desc-title {
    width: 92vw;
    margin: 32px auto 0;
    font-size: 1.2rem;
    color: #000;
  }
  .work-detail-container .detail-title {
    margin: 36px 0 0 0;
  }
  .work-detail-container .detail-title h1 {
    font-size: 1.4rem;
    color: #222;
  }
  .work-detail-container .detail-title h2 {
    font-size: 1.2rem;
    margin: 8px 0 16px;
    color: #444;
  }
  .work-detail-aside .aside-container {
    margin: 0 auto;
  }
  .aside-container ul {
    margin: 8px 0 0 0;
  }
  .aside-container ul li {
    display: inline-block;
    font-size: 0.9rem;
    line-height: 16pt;
    margin: 0 auto;
    color: #999;
  }
  /* 추가 지면이미지 */
  .desc-container {
    width: 92vw;
    margin: 24px auto;
  }
  .desc-container /deep/ img {
    width: 100%;
    height: auto;
    margin: 16px auto;
  }
  .desc {
    word-break: keep-all;
  }
  .desc /deep/ h1 {
    font-size: 1.7rem;
    line-height: 2.5rem;
  }
  .desc /deep/ h2 {
    font-size: 1.5rem;
    line-height: 2.3rem;
  }
  .desc /deep/ h3 {
    font-size: 1.3rem;
    line-height: 2rem;
  }
  .desc /deep/ h4 {
    font-size: 1.2rem;
    line-height: 1.7rem;
  }
  .desc /deep/ h5 {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
  .desc /deep/ strong {
    font-weight: 700;
  }
  .desc /deep/ em {
    font-style: oblique;
  }
  .desc /deep/ p {
    font-size: 0.8rem;
    line-height: 1.4rem;
  }
  .desc /deep/ iframe {
    margin: 32px auto;
    width: 100%;
    height: 40vh;
  }

  /* 뒤로가기 버튼 */
  .back-btn-wrapper {
    width: 80px;
    height: 80px;
    background: #e60121;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    margin: 56px 0 56px 2vw;
  }
  .back-btn-container {
    width: 80px;
    height: 80px;
    display: table;
  }
  .back-btn {
    text-align: center;
    width: 80px;
    height: 80px;
    font-weight: 900;
    display: table-cell;
    vertical-align: middle;
  }
}
</style>
